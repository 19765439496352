export const FirebaseConfig = {
	"projectId": "moover-ca",
	"appId": "1:852560830219:web:08aaa293dcd7b09a660c0e",
	"databaseURL": "https://moover-ca-default-rtdb.firebaseio.com",
	"storageBucket": "moover-ca.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDNi6IsCQtinTvyw9xbYUDK8zIBH2OjBnw",
	"authDomain": "moover-ca.firebaseapp.com",
	"messagingSenderId": "852560830219",
	"measurementId": "G-ZB0NJZESYZ"
};